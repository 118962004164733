import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { Modal } from 'components/elements'
import AuthSteps from 'components/collections/AuthSteps'
import { useSession } from 'context/Session'

import * as S from './Welcome.styles'

const defaultProps = {}
const propTypes = {}

export const Welcome = () => {
  const router = useRouter()
  const [isOpen, setOpen] = useState(false)
  const { user } = useSession()

  return (
    <S.Wrapper>
      <S.Container>
        <S.Content>
          <S.Title>
            Independent Censorship-Free Media for Health & Biomedical Research
          </S.Title>
          <S.Desc>
            During the pandemic, it became apparent that mainstream media and
            government sought to censor scientists, doctors, and health
            advocates with differing opinions, thus repressing opinions and
            enforcing a singular narrative on all things related to public
            health.
            <br />
            <br />
            TrialSiteNews presents independent biomedical research-focused
            journalism, news, and information aggregation to a global audience,
            and we need your support. Rather than drown you in adverts, we ask
            that you provide a small contribution of $5 per month for unlimited
            access to all articles.
          </S.Desc>

          <S.GetStartedButton
            onClick={() => {
              if (!user) {
                setOpen(true)
              } else {
                router.push('/dashboard')
              }
            }}
          >
            Get Started
          </S.GetStartedButton>
          <Modal
            isOpen={isOpen}
            close={() => setOpen(false)}
            closeOnOutside={false}
          >
            <AuthSteps />
          </Modal>
        </S.Content>
      </S.Container>
    </S.Wrapper>
  )
}

Welcome.defaultProps = defaultProps
Welcome.propTypes = propTypes

export default Welcome
