import styled from 'styled-components'
import { Button } from 'components/elements'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.media.tabletMini} {
    flex-direction: column;
  }
`

export const Container = styled.div`
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  padding: 0 24px;
  height: 100%;
  ${({ theme }) => theme.media.phone} {
    padding: 2vh 24px;
  }
  text-align: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`

export const Title = styled.h1`
  ${({ theme }) => theme.font.h1};
  font-size: 45px;
  line-height: 58px;
  margin-bottom: 24px;
`

export const Desc = styled.div`
  margin-bottom: 64px;
`

export const GetStartedButton = styled(Button)`
  width: 100%;
  max-width: 296px;
  height: 56px;
`

export const AskForAFavor = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.grey2};
  background-color: ${({ theme }) => theme.colors.error};
  padding: 2px;
  width: 100%;
  max-width: 800px;
  text-align: center;
`

export const AskForAFavorHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.font.body2};
`

export const AskForAFavorDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.font.body2};
`

export const StyledImg = styled.img`
  aspect-ratio: 1;
  max-width: 300px;
  height: fit-content;
  ${({ theme }) => theme.media.phone} {
    margin: 0 auto 30px;
  }
`
export const StyledLink = styled.a`
  display: contents;
`
